<template>
    <section id="navbarSection" class="container">
        <nav class="navbar navbar-expand-lg transparent-navbar fixed-top">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img loading="lazy" src="../assets/images/webep/new_logo.webp" height="60" alt="c4x Logo">
                </a>
                <button class="navbar-toggler d-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
                        <li class="nav-item ">
                            <a class="nav-link" aria-current="page" href="#aboutSection"><i
                                    class="fa-solid fa-people-group me-2"></i>About Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#productsSection"><i
                                    class="fa-solid fa-list me-2"></i> Products</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#featuresSection"><i class="fa-regular fa-star me-2"></i>Features</a>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#contactSection"><i
                                    class="fa-regular fa-address-card me-2"></i> Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
</template>

<script>
export default {
    name: "NavbarComponent",
    mounted() {
        window.addEventListener('scroll', function () {
            var navbar = document.querySelector('.navbar');
            if (window.scrollY > 0) {
                navbar.classList.add('bg-body-tertiary');
                navbar.classList.remove('transparent-navbar');
                document.querySelector('.navbar-toggler').classList.remove('d-none');
            } else {
                navbar.classList.add('transparent-navbar');
                navbar.classList.remove('bg-body-tertiary');
                document.querySelector('.navbar-toggler').classList.add('d-none');
            }
        });
    }
}
</script>

<style></style>