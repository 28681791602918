<template>
    <section id="productsSection" class="container-fluid p-2 p-lg-5">
        <div class="row justify-content-center pb-3">
            <h6 class="section-heading text-center  text-secondary"> <i
                class="fa-solid fa-list me-2"></i> <b>Our Products</b></h6>
            <hr>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-3">
                <div class="card border-0 shadow-sm mb-3" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img loading="lazy" src="../assets/images/webep/aac.webp" class="img-fluid rounded-start" alt="...">
                        </div>
                        <div class="col-md-8 align-self-center">
                            <div class="card-body text-center">
                                <h6 class="card-title"><b>AAC Blocks</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-0 shadow-sm mb-3" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img loading="lazy" src="../assets/images/webep/block-jointing-mortar.webp" class="img-fluid rounded-start"
                                alt="...">
                        </div>
                        <div class="col-md-8 align-self-center">
                            <div class="card-body text-center">
                                <h6 class="card-title"><b>Block Jointing Moratar</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-0 shadow-sm mb-3" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img loading="lazy" src="../assets/images/webep/readymix-plaster.webp" class="img-fluid rounded-start" alt="...">
                        </div>
                        <div class="col-md-8 align-self-center">
                            <div class="card-body text-center">
                                <h6 class="card-title"><b> Ready Mix Plaster</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card border-0 shadow-sm mb-3" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img loading="lazy" src="../assets/images/webep/panels.webp" class="img-fluid rounded-start" alt="...">
                        </div>
                        <div class="col-md-8 align-self-center">
                            <div class="card-body text-center">
                                <h6 class="card-title"><b>AAC Panels</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card border-0 shadow-sm mb-3" style="max-width: 540px;">
                    <div class="row g-0">
                        <div class="col-md-4">
                            <img loading="lazy" src="../assets/images/webep/building-material.webp" class="img-fluid rounded-start" alt="...">
                        </div>
                        <div class="col-md-8 align-self-center">
                            <div class="card-body text-center">
                                <h6 class="card-title"><b>Building Material</b></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: "ProductComponent"
}
</script>

<style></style>