<template>
    <section id="aboutSection" class="container-fluid">
        <div class="row pt-3">
            <div class="col-md-5 d-none d-lg-block p-5">
                <div class="card shadow img-thumbnail border-0 rounded">
                    <img loading="lazy" src="../assets/images/webep/about.webp" height="500" alt="">
                </div>

            </div>
            <div class="col-md  align-self-center text-center p-5">
                <div class="row justify-content-center">
                    <h6 class="section-heading text-center  text-secondary text-center"> <i
                        class="fa-solid fa-people-group me-1"></i> <b>About Us</b> </h6>
                </div>

                <hr>
                <p class="text-center">Welcome to <b>Phalke Industries</b>, creators of <b>C4X Ultra</b> AAC Blocks.
                    Nestled in the heart of Temburni, we
                    are dedicated to revolutionizing the construction industry with our innovative and eco-friendly
                    building solutions. At Phalke Industries, we pride ourselves on delivering superior quality AAC
                    blocks that embody strength, durability, and sustainability. With a commitment to excellence and a
                    passion for innovation, we strive to be the preferred choice for builders, architects, and
                    homeowners alike. Choose C4X Ultra for your next construction project and experience the difference
                    in quality and performance.</p>
                <p class="text-center">Our vision is to be a pioneer in the building materials industry, offering
                    sustainable, efficient, and high qua lity products that
                    revolutionize construction</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutComponent"
}
</script>

<style></style>