<template>
    <section id="contactSection" class="container-fluid p-2 p-lg-5">
        <div class="card border-0 shadow-sm p-4">
            <div class="row">

                <div class="col-md-6 align-self-center pb-4">
                    <div class="row justify-content-center">
                        <h6 class="section-heading text-center  text-secondary"> <i
                                class="fa-regular fa-address-card me-1"></i> <b>Get In Touch</b></h6>
                        <hr>
                    </div>

                    <div class="row w-100 pt-3" style="display: flex; align-items: center;">
                        <h4 class="col-1"><i class="fa-solid fa-location-dot mr-3 text-secondary"></i></h4>
                        <p class="col-11 mb-0">Pune-Solapur Highway, Tembhurni, Maharashtra 413211</p>
                    </div>

                    <div class="row w-100 pt-3" style="display: flex; align-items: center;">
                        <h4 class="col-1"><i class="fa-regular fa-envelope text-secondary"></i></h4>
                        <p class="col-11 mb-0">contact@c4xultra.com</p>
                    </div>

                    <div class="row w-100 pt-3" style="display: flex; align-items: center;">
                        <h4 class="col-1"><i class="fa-brands fa-whatsapp text-secondary"></i></h4>
                        <p class="col-11 mb-0">+91 7875004403</p>
                    </div>

                    <div class="row w-100 pt-3" style="display: flex; align-items: center;">
                        <h4 class="col-1"><i class="fa-solid fa-phone-volume text-secondary"></i></h4>
                        <p class="col-11 mb-0">+91 9067170007</p>
                    </div>


                </div>
                <div class="col-md-6 d-flex">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3793.897547703495!2d75.1946181!3d18.0299577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22b5b69275c57e9%3A0x7c3c2f1756cf00b7!2sC4X%20ULTRA!5e0!3m2!1sen!2sin!4v1714296009887!5m2!1sen!2sin"
                        width="4500" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactComponent"
}
</script>

<style></style>