<template>
  <div class="c4x-app">
    <NavbarComponent />
    <WelcomeComponent />
    <AboutComponent />
    <ProductComponent />
    <TechnicalSpecificationsComponent />
    <FeaturesComponent />
    <ContactComponent />
    <FooterComponent />
  </div>
</template>

<script>
import AboutComponent from './components/About.vue';
import ContactComponent from './components/Contact.vue';
import FeaturesComponent from './components/Features.vue';
import FooterComponent from './components/Footer.vue';
import NavbarComponent from './components/Navbar.vue';
import ProductComponent from './components/Product.vue';
import TechnicalSpecificationsComponent from './components/TechnicalSpecifications.vue';
import WelcomeComponent from './components/Welcome.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    WelcomeComponent,
    AboutComponent,
    ProductComponent,
    TechnicalSpecificationsComponent,
    FeaturesComponent,
    ContactComponent,
    FooterComponent
  }
}
</script>

<style>
@import "bootstrap/dist/css/bootstrap.css";
@import "@fortawesome/fontawesome-svg-core";
/* @import "@fortawesome/free-brands-svg-icons"; */
/* @import "@fortawesome/free-regular-svg-icons" */
/* @import "@fortawesome/free-solid-svg-icons" */
/* @import "@fortawesome/vue-fontawesome"; */
@import "./assets/stylesheet/common.css";
</style>
