<template>
    <section id="featuresSection" class="container-fluid p-2 p-lg-5">
        <div class="row justify-content-center">
            <h6 class="section-heading text-center  text-secondary"> <i class="fa-regular fa-star me-1"></i> <b>Benefits of C4X AAC Blocks</b></h6>
            <hr>
        </div>

        <div id="featuresTable" class="table-section overflow-auto container">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Cost Component</th>
                        <th scope="col">Saving In Component</th>
                        <th scope="col">Imapact On Project Cost</th>
                        <th scope="col">Explanation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(benefitKey, benefitIndex) in benefits" :key="benefitIndex">
                        <td>{{ benefitKey.component }}</td>
                        <td>{{ benefitKey.saving }}</td>
                        <td>{{ benefitKey.impact }}</td>
                        <td>{{ benefitKey.explanation }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="row pt-3">
            <div class="col-md-3 text-center pb-3">
                <div class="card shadow-sm border-0 h-100 mx-auto" style="width: 18rem;">
                    <img loading="lazy" src="../assets/images/webep/eco-friendly.webp" height="200" class="card-img-top" alt="eco-friendly">
                    <div class="card-body">
                        <h6 class="card-title"><b>Eco-Friendly Construction Solutions</b></h6>
                        <p class="card-text text-secondary">Embrace sustainability with C4X Ultra AAC Blocks, crafted
                            using
                            environmentally friendly materials and manufacturing processes. Reduce your carbon
                            footprint without compromising on strength or durability.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 text-center pb-3">
                <div class="card border-0 shadow-sm h-100 mx-auto" style="width: 18rem;">
                    <img loading="lazy" src="../assets/images/webep/thermal-insulation.webp" height="200" class="card-img-top"
                        alt="thermal-insulation">
                    <div class="card-body">
                        <h6 class="card-title"><b>Superior Thermal Insulation</b></h6>
                        <p class="card-text  text-secondary">Experience unmatched thermal comfort with our AAC blocks.
                            Their
                            cellular structure ensures excellent insulation properties, keeping interiors cooler in
                            summer and warmer in winter, leading to energy savings.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 text-center pb-3">
                <div class="card border-0 shadow-sm h-100 mx-auto" style="width: 18rem;">
                    <img loading="lazy" src="../assets/images/webep/light-weight.webp" height="200" class="card-img-top"
                        alt="thermal-insulation">
                    <div class="card-body">
                        <h6 class="card-title"><b>Lightweight yet Strong</b></h6>
                        <p class="card-text  text-secondary">Enjoy the benefits of lightweight construction without
                            compromising on
                            structural integrity. C4X Ultra AAC Blocks offer exceptional strength-to-weight ratio,
                            making them ideal for a wide range of building applications.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 text-center pb-3">
                <div class="card border-0 shadow-sm h-100 mx-auto" style="width: 18rem;">
                    <img loading="lazy" src="../assets/images/webep/Durable.webp" height="200" class="card-img-top" alt="thermal-insulation">
                    <div class="card-body">
                        <h6 class="card-title"><b>Long-lasting Durability</b></h6>
                        <p class="card-text  text-secondary">Invest in quality that lasts a lifetime. Our AAC blocks are
                            engineered
                            to withstand the test of time, offering long-lasting durability and minimal maintenance
                            requirements, saving you time and money in the long run.</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: "FeaturesComponent",
    data() {
        return {
            benefits: [
                {
                    component: "Mortar Material",
                    saving: "60%",
                    impact: "02%",
                    explanation: `C4x Aac Blocks Are 7 Times the Size of
Conventional Bricks. Resulting In 1/3rd The
Number of Joints. Thus, An Overall Mortar
Savings Up To 60%`
                },
                {
                    component: "Plastering Material",
                    saving: "35%",
                    impact: "02%",
                    explanation: `Exceptional dimensional accuracy & smooth
surfaces. Eliminates need of three coat plaster
walls & allows for a final 6 mm skin coat
(putty/gypsum plaster)
`
                },
                {
                    component: "Wastage",
                    saving: "10%",
                    impact: "0.5%",
                    explanation: `Breakage in bricks might be as high as 15%
which in case of aac blocks is less than 5%`
                },
                {
                    component: "Structural Material(Steel & Concrete)",
                    saving: "20%",
                    impact: "08%",
                    explanation: `Being light weight. Mc blocks drastically reduce
the dead weight of the building. This translates
to design of lighter structures leading to
reduction in steel and concrete (up to 20%)`
                },
                {
                    component: "Increase In Floor Space Area",
                    saving: "02%",
                    impact: "02%",
                    explanation: `Being to exceptional thermal insulation &
weather barrier properties, it’s possible to use
thinner blocks, which results in increase of
carpet area.
`
                },
                {
                    component: "Saving In Capex For HVAC Systems",
                    saving: "30%",
                    impact: "0.5%",
                    explanation: `Aac blocks have excellent insulation properties,
which results in saving in capex & opex of hvac
systems.`
                }
            ]
        }
    },
}
</script>

<style></style>