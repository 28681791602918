<template>
    <section id="welcomeSection" class="">
        <div class="welcome-image">
            <img loading="lazy" src="../assets/images/webep/c4xWelcomeImage.webp" style="height: 100vh; width: 100%;" alt="">
            <div class="row w-100 justify-content-center p-5 tag-line" style="position: absolute; top: 45vh;">
                <h1 style="backdrop-filter: blur(10px) saturate(90%);"
                    class="bungee-outline-regular text-center text-warning"><strong>"Building the Future with AAC
                        Excellence"</strong>
                </h1>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WelcomeComponent"
}
</script>

<style></style>