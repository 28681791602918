<template>
    <section id="techSpecSection" class="container-fluid p-2 p-lg-5">
        <div class="heading">
            <div class="row justify-content-center pb-3">
                <h6 class="section-heading text-center  text-secondary"> <i class="fa-solid fa-scale-unbalanced-flip me-1"></i> <b>Technical Specifications of AAC Block</b>
                </h6>
            </div>

            <hr>
        </div>
        <div id="technicalSpecifications" class="table-section overflow-auto container pb-4">
            <table class="table table-striped bg-none">
                <thead>
                    <tr>
                        <th scope="col">Product Specifications</th>
                        <th scope="col">Unit</th>
                        <th scope="col">Capacity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(specKey, specIndex) in technicalSpecifications.specifications" :key="specIndex">
                        <td>{{ specKey.spec }}</td>
                        <td>{{ specKey.unit }}</td>
                        <td>{{ specKey.capacity }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="dimensions" class="table-section overflow-auto container ">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Dimensions of AAC Block in MM</th>
                        <th scope="col">Dimensions of AAC Block in INCHES</th>
                        <th scope="col">No. of Blocks in 1 CUM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dimensionKey, specIndex) in technicalSpecifications.dimensions" :key="specIndex">
                        <td>{{ dimensionKey.inmm }}</td>
                        <td>{{ dimensionKey.ininch }}</td>
                        <td>{{ dimensionKey.incum }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script>
export default {
    name: "TechnicalComponent",
    data() {
        return {
            technicalSpecifications: {
                specifications: [
                    {
                        spec: "LENGTH",
                        unit: "mm",
                        capacity: "600"
                    },
                    {
                        spec: "HEIGHT",
                        unit: "mm",
                        capacity: "200"
                    },
                    {
                        spec: "THICKNESS",
                        unit: "mm",
                        capacity: "75/100/125/150/200/225/250"
                    },
                    {
                        spec: "COMPRESSION STRENGTH",
                        unit: "N/mm2",
                        capacity: "> 4 (As per IS2185 part III)"
                    },
                    {
                        spec: "NORMAL DRY DENSITY",
                        unit: "Kg/m3",
                        capacity: "550-650"
                    },
                    {
                        spec: "THERMAL CONDUCTIVITY",
                        unit: "W/mk",
                        capacity: "0.24"
                    },
                    {
                        spec: "SOUND REDUCTION",
                        unit: "Db",
                        capacity: "Up to 42"
                    },
                    {
                        spec: "FIRE RESISTANCE",
                        unit: "Hrs",
                        capacity: "*4"
                    },
                    {
                        spec: "DRY SHRINKAGE",
                        unit: "%",
                        capacity: "0.04"
                    }
                ],
                dimensions: [
                    {
                        inmm: "600 X 200 X 075 MM",
                        ininch: "24 X 08 X 03 INCHES",
                        incum: "111 BLOCKS PER CUM"
                    },
                    {
                        inmm: "600 X 200 X 100 MM",
                        ininch: "24 X 08 X 04 INCHES",
                        incum: "083 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 125 MM",
                        ininch: "24 X 08 X 05 INCHES",
                        incum: "066 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 150 MM",
                        ininch: "24 X 08 X 06 INCHES",
                        incum: "055 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 175 MM",
                        ininch: "24 X 08 X 07 INCHES",
                        incum: "047 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 200 MM",
                        ininch: "24 X 08 X 08 INCHES",
                        incum: "041 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 225 MM",
                        ininch: "24 X 08 X 09 INCHES",
                        incum: "37 BLOCKS PER CUM"
                    }, {
                        inmm: "600 X 200 X 250 MM",
                        ininch: "24 X 08 X 10 INCHES",
                        incum: "033 BLOCKS PER CUM"
                    }
                ]
            }
        }
    },
}
</script>

<style></style>