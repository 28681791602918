<template>
    <section id="footer" class="pt-3">
        <footer class="row m-0 p-0 text-center bg-light align-items-center shadow-sm p-2 shadow">
            <div class="col-md align-items-center">
                <span class="mb-3 mb-md-0 text-muted">© 2024-25 Phalke Industries</span>
            </div>

            <div class="col-md rounded-social-buttons container-fluid m-0 pt-2">
                <div class="row m-0 p-0 justify-content-center">
                    <a class="social-button facebook bg-secondary"
                        href="https://www.facebook.com/people/C4X-ULTRA-AAC/61558737879052/" target="_blank"><i
                            class="fab fa-facebook-f"></i></a>
                    <!-- <a class="social-button twitter" href="https://www.twitter.com/" target="_blank"><i
                        class="fab fa-twitter"></i></a>
                <a class="social-button linkedin" href="https://www.linkedin.com/" target="_blank"><i
                        class="fab fa-linkedin"></i></a>
                <a class="social-button tiktok" href="https://www.tiktok.com/" target="_blank"><i
                        class="fab fa-tiktok"></i></a>
                <a class="social-button youtube" href="https://www.youtube.com/" target="_blank"><i
                        class="fab fa-youtube"></i></a> -->
                    <a class="social-button instagram bg-secondary"
                        href="https://www.instagram.com/c4x_ultra/?igsh=eWZ1YmUxODExNmtx&utm_source=qr"
                        target="_blank"><i class="fab fa-instagram"></i></a>
                </div>

            </div>
            <div class="col-md d-none d-flex align-items-end">
                <span class="mb-3 mb-md-0 ms-auto text-muted"><i class="fa-solid fa-laptop-code"></i> Designed &
                    Developed by
                    Pawan Khande</span>
            </div>
        </footer>
    </section>
</template>

<script>
export default {
    name: "FooterComponent"
}
</script>

<style></style>